/*
 * RUN ISOTOPE
 */
if ($('.isotope').length) {

  setTimeout(
    function() {
      var $grid = $('.isotope').isotope({
        itemSelector: '.grid-item',
        hiddenStyle: {
          opacity: 0
        },
        visibleStyle: {
          opacity: 1
        }
      });

      /*
       * ANIMATION ON START
       */
      var $items = $grid.find('.grid-item');
      $grid.addClass('is-showing-items');

      /*
       * FILTER ISOTOPE
       */
      $('.filters-button-group').on('click', '.filter', function(e) {
        e.preventDefault();
        $('.filters-button-group .filter').removeClass('is-checked');
        $(this).addClass('is-checked');
        var filterValue = $(this).attr('data-filter');
        $grid.isotope({
          filter: filterValue
        });
      });
    }, 1000
  );

}
